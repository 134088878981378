import { File } from "@/resources/interfaces";

export function downloadUrl(file?: Partial<File>, preview = false) {
  if (!file?.id) return "";
  let params = "download=1";
  if (preview) {
    params += "&preview=1";
  }
  const url = `${process.env.VUE_APP_API_BASE}/file/${
    file.id
  }?${params}&token=${localStorage.getItem("token")}`;
  return url;
}
