import { http } from "@/resources";
import { Config, ListTemplate, Product, User } from "@/resources/interfaces";
import store from "@/store";

export const config = {} as Config;

async function getHttpData(path: string) {
  return new Promise((resolve) => {
    http
      .get(path)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.error(err.message);
        resolve(undefined);
      });
  });
}

const loadConfig = async (configLoaded: Partial<Config> = {}) => {
  const [commonConfig, products, user, fixedListTemplates] = await Promise.all([
    getHttpData("config"),
    configLoaded.products || getHttpData("product"),
    configLoaded.user?.id
      ? configLoaded.user
      : localStorage.token && getHttpData("auth/user"),
    configLoaded.fixedListTemplates || getHttpData("list-template?fixed=true"),
  ]);

  if (user) {
    store.commit("SET_AUTH_USER", {
      login: (user as User)?.login,
      role: (user as User)?.role,
      passwordExpiresAt: (user as User)?.passwordExpiresAt,
      tags: (user as User)?.tags,
    });
  }

  const configAggregated = {
    ...(commonConfig as Config),
    products: products as Product[],
    user: user as User,
    fixedListTemplates:
      (fixedListTemplates as ListTemplate[] | undefined)?.map((t) => ({
        count: NaN,
        ...t,
      })) || [],
  };

  Object.assign(config, configAggregated);

  return configAggregated;
};

export default loadConfig;

export const defaultConfig = {
  daysPerMonth: 20,
  weeksPerMonth: 4,
  techFee: 299,
  minPrincipal: 10000,
  houseOriginationFee: 0.02,
  vb: 0.01,
  repCommissionDiscount: [],
  repCommissionDiscountByMtdVolume: [],
  stipLabels: [],
  entityTags: [],
  usStates: {},
  mcaContractRules: [],
  docusign: {
    accessToken: "",
    expiresAt: new Date(),
  },
  experian: {
    accessToken: "",
    expiresAt: new Date(),
  },
  applicationIdAutoIncrement: 1,
  riskModelDesc: {},
  schedulePISplitTheta: {
    weekly: 5 / 261,
    dailyDoubleDebit: 1 / 261,
    dailyNonDoubleDebit: 1 / 251,
  },
};
